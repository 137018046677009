import { useRoute } from '#imports'
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import type { Features } from '~/features'
import { isIncludedInRoutes } from '~/utils/navigation/isIncludedInRoutes'

import { ROUTES as AUTH_ROUTE_NAMES } from '../auth/route-names'
import { CHECKOUT as CHECKOUT_ROUTE_NAMES } from '../checkout/routes-names'
import { PRODUCT } from '../product/route-names'
import { ROUTES as REVIEWS_ROUTE_NAMES } from '../reviews/route-names'

import { TRACKING_ZONE } from './SoftPrompt.constants'
/**
 * The prompt can be seen once per session.
 * It should not be displayed on a specific subset of routes
 * nor on countries where the app is not available
 */
export function usePrompt() {
  // 10 seconds in milliseconds
  const TIME_BEFORE_SHOWING_SOFT_PROMPT = 10 * 1_000
  const SESSION_STORAGE_KEY = 'soft_prompt_seen'
  const APP_DOWNLOAD_BASE_URL = 'https://app.backmarket.com/Mlqg'
  const CAMPAIGN = 'WEB_AppDownloadPrompt'

  const route = useRoute()
  const routeName = route.name?.toString()
  const tracking = useTracking()

  const {
    features,
    market: { countryCode },
  } = useMarketplace<Features>()

  const isVisible = ref(false)
  let timeout: NodeJS.Timeout

  function getCanDisplayPrompt() {
    const DO_NOT_DISTURB_ROUTE_NAMES = [
      CHECKOUT_ROUTE_NAMES,
      AUTH_ROUTE_NAMES,
      PRODUCT.HOME,
      REVIEWS_ROUTE_NAMES.FORM,
    ]

    const alreadySeen = window.sessionStorage
      ? window.sessionStorage.getItem(SESSION_STORAGE_KEY) === 'true'
      : true

    const canBeDisturbed = !isIncludedInRoutes(
      routeName,
      DO_NOT_DISTURB_ROUTE_NAMES,
    )

    const isAppAvailable = features.mobileApp.available

    return !alreadySeen && canBeDisturbed && isAppAvailable
  }

  function onPromptClosed() {
    isVisible.value = false
    sessionStorage.setItem(SESSION_STORAGE_KEY, 'true')

    tracking.trackClick({
      zone: TRACKING_ZONE,
      name: 'close',
    })
  }

  function buildLink({ xp, pid }: { xp: string; pid: string }) {
    return `${APP_DOWNLOAD_BASE_URL}?af_xp=${xp}&pid=${pid}&c=ALL_${countryCode}_${CAMPAIGN}&af_dp=backmarket://start/home&af_source=${routeName}`
  }

  const qrCode = buildLink({
    xp: 'qr',
    pid: 'QR_code',
  })

  const link = buildLink({
    xp: 'Website',
    pid: 'Website',
  })

  function trackImpression() {
    if (isVisible.value) {
      tracking.trackPromptNotificationImpression({
        zone: TRACKING_ZONE,
      })
    }
  }

  onMounted(() => {
    timeout = setTimeout(() => {
      isVisible.value = getCanDisplayPrompt()
    }, TIME_BEFORE_SHOWING_SOFT_PROMPT)
  })

  watch(isVisible, () => {
    trackImpression()
  })

  onBeforeUnmount(() => {
    clearTimeout(timeout)
  })

  return {
    canDisplayPrompt: isVisible,
    onPromptClosed,
    qrCode,
    link,
  }
}
