import type { Locale, MarketCountryCode } from '@backmarket/http-api'

export const languagesDict: { [localePrefix: string]: string } = {
  fr: 'français',
  en: 'English',
  de: 'Deutsch',
  es: 'español',
  it: 'italiano',
  fi: 'suomi',
  el: 'Ελληνικά',
  ja: '日本語',
  nl: 'Nederlands',
  pt: 'português',
  sv: 'svenska',
  sk: 'Slovenčina',
}

export function countryDisplayName(
  locale: Locale,
  countryCode: MarketCountryCode,
) {
  const localePrefix = locale.split('_')[0]
  const language = languagesDict[localePrefix] || ''

  return `${language} (${countryCode})`
}
