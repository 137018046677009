<template>
  <div class="px-24 py-16">
    <div class="text-static-default-mid body-2 mb-16">
      {{ i18n(translations.description) }}
    </div>
    <RevInputText
      id="country-switch-search-country"
      v-model="countryLookup"
      :icon="IconSearch"
      :label="i18n(translations.searchCountryLabel)"
      type="text"
      @clear="() => (countryLookup = '')"
    />
  </div>

  <RevList
    class="body-2-bold divide-y-0 overflow-auto border-y-0"
    :class="$style.listHeight"
    :variant="variant"
  >
    <div v-if="!countryLookup">
      <div class="px-24 pt-12 lg:pb-4">
        {{ i18n(translations.currentLocationTitle) }}
      </div>
      <CountrySwitchMenuListItem
        :key="thisMarket.countryCode"
        :countryCode="thisMarket.countryCode"
        :defaultLocale="thisMarket.defaultLocale"
      />
      <div class="px-24 py-12">
        <RevDivider class="grow" />
      </div>
      <div class="px-24 pb-4 pt-8">
        {{ i18n(translations.currentAllMarketsTitle) }}
      </div>
    </div>
    <CountrySwitchMenuListItem
      v-for="market in filteredMarkets"
      :key="market.countryCode"
      :countryCode="market.countryCode"
      :defaultLocale="market.defaultLocale"
    />
  </RevList>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevDivider } from '@ds/components/Divider'
import { RevInputText } from '@ds/components/InputText'
import { RevList } from '@ds/components/List'
import type { ListVariant } from '@ds/components/List/List.constant'
import { IconSearch } from '@ds/icons/IconSearch'

import { countryDisplayName } from '../../utils/countryDisplayName'

import translations from './CountrySwitch.translations'
import CountrySwitchMenuListItem from './CountrySwitchMenuListItem.vue'

withDefaults(
  defineProps<{
    variant?: ListVariant
  }>(),
  { variant: 'default' },
)

const i18n = useI18n()
const thisMarket = useMarketplace().market
const { allMarkets } = useMarketplace()
const countryLookup = ref('')

const filteredMarkets = computed(() => {
  const input = countryLookup.value.toLowerCase()
  if (!input) return Object.values(allMarkets)

  return Object.values(allMarkets).filter(
    (market) =>
      i18n.country(market.countryCode).toLowerCase().includes(input) ||
      countryDisplayName(market.defaultLocale, market.countryCode)
        .toLowerCase()
        .includes(input),
  )
})
</script>

<style module>
@media (min-width: 1200px) {
  .listHeight {
    max-height: 40vh;
  }
}
</style>
