<template>
  <RevBackdrop
    v-if="isMenuActive"
    class="z-10 hidden backdrop-blur-2xl lg:block"
  />
  <header
    id="header"
    class="bg-surface-default-low border-static-default-low sticky top-0 z-10 border-b transition-all duration-200"
    :class="{ '-translate-y-full': headerIsHidden && !isOpen }"
  >
    <HeaderBar
      :class="{ 'hidden lg:flex': isSmallScreenHeaderExperimentEnabled }"
      data-qa="header-bar"
    />
    <HeaderBarSmallScreen
      v-if="isSmallScreenHeaderExperimentEnabled"
      class="lg:hidden"
      data-qa="header-bar"
    />
    <NavBar />
  </header>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { RevBackdrop } from '@ds/components/Backdrop'

import { useHideOnScroll } from '../composables/useHideOnScroll'
import { useNavigation } from '../composables/useNavigation'

import HeaderBar from './HeaderBar.vue'
import HeaderBarSmallScreen from './HeaderBarSmallScreen.vue'
import NavBar from './NavBar.vue'

const { isMenuActive, fetchMenuItems, isOpen } = useNavigation()
await fetchMenuItems()

const { hidden: headerIsHidden } = useHideOnScroll()
const experiments = useExperiments()

const isSmallScreenHeaderExperimentEnabled = computed(() => {
  return experiments['experiment.reduceHeader'] === 'small'
})
</script>
